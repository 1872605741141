import * as React from "react";
import {Link} from "gatsby";
import Layout from "../../components/layout/Layout";
import SplashBanner from "../../components/SplashBanner";
import {RenewablesTabs} from "../../components/tabs/RenewablesTabs";
import FullWidthCTA from "../../components/FullWidthCTA";
import Testimonials from "../../components/testimonials/Testimonials";

const bannerImg = '../../banners/renewable-energy_banner.jpg';

const ctaBackgroundImg = '../../article/renewable-energy_overview-banner.jpg';

//RENDER
export default function RenewablesPage() {
    return (
        <Layout pageTitle={'Renewable Energy'}>
            <SplashBanner
                title={"Renewable Energy"}
                content={["Helping clients achieve their ",
                    <span>Net Zero</span>, " Targets via the mobilisation of competent personnel."]}
                backgroundImg={bannerImg}
            />


            <section>
                <RenewablesTabs/>
            </section>

            <FullWidthCTA id={'oilAndGasOverview'} ctaBackgroundImg={ctaBackgroundImg}>
                <h3>Overview</h3>
                <p>Supporting Renewable Energy companies in the same way that we have demonstrated our worth to
                    companies in the Upstream sector for over a decade. Creating ‘outside the box’ solutions for our
                    customers, to ensure that competency is not sacrificed when engaging a third party for technical
                    personnel.</p>
                <p>It’s this approach that will help customers reduce NPT, CAPEX & OPEX expenditure while increasing
                    competency and HSE standards on their projects.</p>
                <div className="double-button" style={{marginTop: '40px'}}>
                    {/*<a className="button inverted primary arrow-light" href="/">*/}
                    {/*    <span>Download our brochure</span>*/}
                    {/*</a>*/}
                    <Link className="button inverted primary arrow-light"
                          to={'/contact-us'}>
                        <span>Contact us</span>
                    </Link>
                </div>
            </FullWidthCTA>

            <Testimonials/>

        </Layout>
    )
}
