import * as React from 'react';
import {useTabs, TabPanel} from "react-headless-tabs"
import {TabSelector} from './TabSelector';

import {
    container,
    tabContent,
    splitContent,
    text,
    grid,
    grid__column,
    media
} from "../../scss-modules/tabs.module.scss";

export function RenewablesTabs() {
    const [selectedTab, setSelectedTab] = useTabs([
        'windEnergy',
        'hydroelectric',
        'geothermal'
    ]);

    return (
        <div className={`container large-container ${container}`}>
            <aside>
                <nav className="sidebar-nav">
                    <ul className="sidebar-list">
                        <TabSelector isActive={selectedTab === 'windEnergy'}
                                     onClick={() => setSelectedTab('windEnergy')}>
                            Wind Energy
                        </TabSelector>
                        <TabSelector isActive={selectedTab === 'hydroelectric'}
                                     onClick={() => setSelectedTab('hydroelectric')}>
                            Hydroelectric
                        </TabSelector>
                        <TabSelector isActive={selectedTab === 'geothermal'}
                                     onClick={() => setSelectedTab('geothermal')}>
                            Geothermal
                        </TabSelector>
                    </ul>
                </nav>
            </aside>


            {/*Content*/}
            <div className={`container`} style={{width: '100%'}}>
                <div className="content">
                    <TabPanel className={tabContent} hidden={selectedTab !== 'windEnergy'}>
                        <div className={splitContent}>
                            <div className={text}>
                                <h3>Wind Energy</h3>
                                <div className={grid}>
                                    <div className={grid__column}>
                                        <ul className="formatted-list">
                                            <li>Engineering</li>
                                            <li>Morning/Marine</li>
                                            <li>Construction</li>
                                            <li>Fabrication</li>
                                            <li>Installation</li>
                                            <li>Inspection Services</li>
                                            <li>Electricians</li>
                                        </ul>
                                    </div>
                                    <div className={grid__column}>
                                        <ul className="formatted-list">
                                            <li>Mechanics</li>
                                            <li>Project Managers</li>
                                            <li>OIM</li>
                                            <li>Site Management</li>
                                            <li>Commercial Management</li>
                                            <li>HSE</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className={media}>
                                <img src={'../../renewables/renewables-wind.jpg'} alt="Wind Energy"/>
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel className={tabContent} hidden={selectedTab !== 'hydroelectric'}>
                        <div className={splitContent}>
                            <div className={text}>
                                <h3>Hydroelectric</h3>
                                <ul className="formatted-list">
                                    <li>Engineering</li>
                                    <li>Management</li>
                                    <li>Site Management</li>
                                    <li>Site Supervision</li>
                                    <li>Support Services</li>
                                </ul>
                            </div>
                            <div className={media}>
                                <img src={'../../renewables/renewables-hydroelectric.jpg'} alt="Hydroelectric"/>
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel className={tabContent} hidden={selectedTab !== 'geothermal'}>
                        <div className={splitContent}>
                            <div className={text}>
                                <h3>Geothermal</h3>
                                <ul className="formatted-list">
                                    <li>Engineering</li>
                                    <li>Management</li>
                                    <li>Site Management</li>
                                    <li>Site Supervision</li>
                                    <li>Support Services</li>
                                </ul>
                            </div>
                            <div className={media}>
                                <img src={'../../renewables/renewables-geothermal.jpg'} alt="Geothermal"/>
                            </div>
                        </div>
                    </TabPanel>


                </div>
            </div>
        </div>
    );
}